import { Block, BlockCreation, BlockPlaceholderChange, BlockSpeakChange, BlockVideoChange, LanguageCode } from '@visikon/core-models/content';
import { useForceRender } from 'commons/useForceRender';
import useRefState from 'commons/useRefState';
import { createUniqueKey } from 'commons/utils';
import { ArchiveButton } from 'components/ArchiveButton';
import { LanguageSelector } from 'components/LanguageSelector';
import { TagList } from 'components/tags/TagList';
import React, { useState } from 'react';
import { Button, Flag, Form, FormTextArea, Icon, Input, Label } from 'semantic-ui-react';
import { prettyMediaFilenameForShow } from '../blockUtils';
import { getBlockAudioUrl, getBlockVideoUrl } from '../blockResourceSelectors';
import { SpeakVariation } from './variationParts/speak/SpeakVariation';
import { VideoVariation } from './variationParts/video/VideoVariation';
import { variationDefaultIMGURL } from 'config';
import { langFromCode } from 'commons/languages';
import {
  Container,
  TitleEditor,
  VariationArea,
  LanguageContainer,
  VariationListContainer,
  AddVariationButton,
  LanguageIndicator,
  TagsContainer,
} from './BlockEntryEditor.styles';

const PLATFORM_READY_TAG = 'Platform-Ready';

interface BlockEntryEditorProps {
  initialValue?: Block;
  lang: LanguageCode;
  hidden?: boolean;
  activeVideoVariation?: string;
  activeSpeakVariation?: string;
  onChange(state: BlockCreation): void;
}

const createBlockCreation: (block: Block) => BlockCreation = (block) => ({
  updateId: block._id,
  name: block.name,
  description: block.description,
  tags: block.tags,
  archived: block.archived,
  speaks: block.speaks.map((s) => ({
    key: s.key,
    variations: s.variations,
    language: s.language,
    text: s.text,
    status: s.status,
    notes: s.notes,
    fileNameForShow: prettyMediaFilenameForShow(s.audioFile ? s.audioFile.src : undefined),
    existingSource: s.audioFile ? getBlockAudioUrl(block, s.language, s.variations[0]) : undefined, // VARIATIONS: Assume only one - fix at some point?
  })),
  videos: block.videos.map((v) => ({
    key: v.key,
    contentType: v.videoFile ? v.videoFile.contentType : undefined,
    language: v.language,
    status: v.status,
    notes: v.notes,
    variations: v.variations,
    fileNameForShow: prettyMediaFilenameForShow(v.videoFile ? v.videoFile.src : undefined),
    existingSource: v.videoFile ? getBlockVideoUrl(block, v.language, v.variations[0]) : undefined, // VARIATIONS: Assume only one - fix at some point?
  })),
});

function InitCreation(initialValue?: Block): BlockCreation {
  if (initialValue) return createBlockCreation(initialValue);

  return {
    name: '',
    tags: [],
    speaks: [],
    videos: [],
  };
}

function filterOnLanguage<A extends { language: LanguageCode }>(lang: LanguageCode) {
  return (somethingWithLanguage: A) => somethingWithLanguage.language === lang;
}

type PartialSetAction<T = BlockCreation, PT = Partial<T>> = PT | ((prevState: T) => PT);
type VideoData = BlockVideoChange & BlockPlaceholderChange;

export function BlockEntryEditor(props: BlockEntryEditorProps) {
  const forceRender = useForceRender();
  const [activeLang, setActiveLang] = useState<LanguageCode>(props.lang);
  const [creation, setCreation, getCreation] = useRefState<BlockCreation>(() => InitCreation(props.initialValue));

  function updateCreationAndReport(value: PartialSetAction<BlockCreation>) {
    const currentCreation = getCreation();

    const patch = typeof value === 'function' ? value(currentCreation) : value;
    const updatedState = {
      ...currentCreation,
      ...patch,
    };

    setCreation(updatedState);
    reportChanges(updatedState);
  }

  const updateVideoInState = (video: VideoData) => {
    const oldVideos = getCreation().videos;
    const idx = oldVideos.findIndex((s) => s.key === video.key);
    let videos: BlockVideoChange[];
    if (idx !== -1) {
      // Replace at index idx
      videos = Object.assign([], oldVideos, { [idx]: video });
    } else {
      // Just add it
      videos = [...oldVideos, video];
    }

    updateCreationAndReport({ videos });
  };

  const updateSpeakInState = (speak: BlockSpeakChange) => {
    const oldSpeaks = getCreation().speaks;
    const idx = oldSpeaks.findIndex((s) => s.key === speak.key);
    let speaks: BlockSpeakChange[];
    if (idx !== -1) {
      // Replace at index idx
      speaks = Object.assign([], oldSpeaks, { [idx]: speak });
    } else {
      // Just add it
      speaks = [...oldSpeaks, speak];
    }

    updateCreationAndReport({ speaks });
    forceRender();
    return speak;
  };

  const addNewSpeak = () => {
    updateSpeakInState({
      key: createUniqueKey(),
      variations: [],
      language: activeLang,
      text: '',
      status: 'YELLOW',
    });
    forceRender();
  };

  const addNewVideo = () => {
    updateVideoInState({
      key: createUniqueKey(),
      variations: [],
      status: 'YELLOW',
      language: activeLang,
      contentType: 'image/png',
      existingSource: variationDefaultIMGURL(),
    });
    forceRender();
  };

  const handleTitleEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCreationAndReport({
      name: event.target.value,
    });
  };

  function reportChanges(changes: BlockCreation) {
    props.onChange(changes);
  }

  const addTag = (tag: string) => {
    updateCreationAndReport((prevState) => ({
      tags: [...prevState.tags.filter((t) => t !== tag), tag],
    }));
    forceRender();
  };

  const removeTag = (tag: string) => {
    updateCreationAndReport((prevState) => ({
      tags: prevState.tags?.filter((t) => t !== tag),
    }));
    forceRender();
  };

  const togglePlatformTag = () => {
    updateCreationAndReport((prevState) => {
      let { tags } = prevState;
      const hasPlatform = tags.includes(PLATFORM_READY_TAG);

      if (hasPlatform) {
        tags = tags.filter((t) => t !== PLATFORM_READY_TAG);
      } else {
        tags.unshift(PLATFORM_READY_TAG);
      }

      return { tags };
    });
    forceRender();
  };

  const removeVideoMedia = (videoKey: string) => {
    updateCreationAndReport((prevState) => {
      const idx = prevState.videos.findIndex((s) => s.key === videoKey);

      if (idx === -1) return prevState;

      const { language, notes, status, variations } = prevState.videos[idx];

      return {
        videos: [
          ...prevState.videos.slice(0, idx),
          {
            key: createUniqueKey(),
            contentType: 'image/png',
            existingSource: variationDefaultIMGURL(),
            language,
            notes,
            status,
            variations,
          },
          ...prevState.videos.slice(idx + 1),
        ],
      };
    });
    forceRender();
  };

  const removeSpeakMedia = (speakKey: string) => {
    updateCreationAndReport((prevState) => {
      const idx = prevState.speaks.findIndex((s) => s.key === speakKey);

      if (idx === -1) return prevState;

      const { language, text, notes, status, variations } = prevState.speaks[idx];

      return {
        speaks: [
          ...prevState.speaks.slice(0, idx),
          {
            key: createUniqueKey(),
            language,
            text,
            notes,
            status,
            variations,
          },
          ...prevState.speaks.slice(idx + 1),
        ],
      };
    });
    forceRender();
  };

  const toggleArchived = () => {
    updateCreationAndReport((prevState) => ({
      archived: !prevState.archived,
    }));
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: description } = event.target;

    updateCreationAndReport({
      description,
    });
    forceRender();
  };

  const languageFlags = props.initialValue?.speaks.map((s, index) => {
    const lang = langFromCode(s.language);
    return <Flag key={index} name={lang.flag as any} />;
  });

  const isPlatformReady = creation.tags.includes(PLATFORM_READY_TAG);

  return (
    <Container hide={!!props.hidden}>
      <TitleEditor>
        <div className="name">
          Block name:
          <br />
          <Input onChange={handleTitleEdit} defaultValue={creation.name} />
        </div>
        <TagsContainer>
          <TagList header="Block tags" tagList={creation.tags} onTagAdded={addTag} onTagRemoved={removeTag} />
        </TagsContainer>

        <div style={{ zIndex: 10 }}>
          <Button onClick={togglePlatformTag} color={isPlatformReady ? 'green' : undefined}>
            {/* checkmark */}
            {/* <Icon name={isPlatformReady ? 'check square outline' : 'square outline'} color="green" /> */}
            {isPlatformReady ? 'Remove' : 'Mark'} Platform Ready
            {/* Toggle Platform Ready */}
          </Button>
          <ArchiveButton entry={creation} onToggle={toggleArchived} style={{ position: 'unset' }} dontShowModal />
        </div>
      </TitleEditor>
      <Form>
        <FormTextArea
          label="Visual Description"
          rows={3}
          placeholder="Description of the visual content of the block"
          value={creation.description}
          onChange={onDescriptionChange}
        />
      </Form>
      <VariationArea>
        <Label attached="top left">Video variations</Label>
        <AddVariationButton onClick={addNewVideo}>Add video</AddVariationButton>
        <VariationList
          list={creation.videos}
          lang={activeLang}
          emptyListText="Add a video"
          renderItem={(variation, blockVariations) => (
            <VideoVariation
              key={variation.key}
              isActiveVariation={variation.variations[0] === props.activeVideoVariation}
              variation={variation}
              blockVariations={blockVariations}
              onChange={updateVideoInState}
              onRemove={removeVideoMedia}
            />
          )}
        />
      </VariationArea>
      <VariationArea>
        <Label attached="top left">Speak variations</Label>
        <LanguageIndicator>{languageFlags}</LanguageIndicator>
        <AddVariationButton onClick={addNewSpeak}>Add speak</AddVariationButton>
        <VariationList
          list={creation.speaks}
          lang={activeLang}
          emptyListText="Add some speak"
          renderItem={(variation, blockVariations) => (
            <SpeakVariation
              key={variation.key}
              isActiveVariation={variation.variations[0] === props.activeSpeakVariation}
              variation={variation}
              blockVariations={blockVariations}
              onChange={updateSpeakInState}
              onRemove={removeSpeakMedia}
              activeLanguage={activeLang}
            />
          )}
        />
      </VariationArea>

      <LanguageContainer>
        Language:
        <br />
        <LanguageSelector activeLang={activeLang} onLanguageChanged={setActiveLang} />
      </LanguageContainer>
    </Container>
  );
}
interface VariationListProps<T extends { language: LanguageCode }> {
  list?: T[];
  lang: LanguageCode;
  emptyListText: string;
  renderItem: (item: T, blockVariations: string[]) => React.ReactNode;
}

function VariationList<T extends { language: LanguageCode; variations: string[] }>({ list, lang, emptyListText, renderItem }: VariationListProps<T>) {
  // Show empty videos area
  if (list === undefined || list.length === 0) {
    return (
      <VariationListContainer>
        <EmptyVariationList text={emptyListText} />
      </VariationListContainer>
    );
  }

  const blockVariationsSet = new Set(list.map((variation) => variation.variations).flat());
  const blockVariations = Array.from(blockVariationsSet.values());

  const filteredList = list.filter(filterOnLanguage(lang));
  return <VariationListContainer>{filteredList.map((variation) => renderItem(variation, blockVariations ?? []))}</VariationListContainer>;
}

function EmptyVariationList({ text }: { text: string }) {
  return (
    <div style={{ color: 'grey' }}>
      <h2>{text}</h2>
      <div
        style={{
          position: 'absolute',
          right: 80,
          top: 30,
          transform: 'rotate(-45deg)',
        }}
      >
        <Icon name="long arrow alternate right" style={{ fontSize: '8em' }} />
      </div>
    </div>
  );
}
