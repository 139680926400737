import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const borderColor = 'rgba(34, 36, 38, .15)';

export const Container = styled.div<{ hide: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LanguageContainer = styled.div`
  border-top: 1px solid ${borderColor};
  padding: 15px;
`;

export const TitleEditor = styled.div`
  padding: 15px;
  display: flex;

  div.name {
    flex: 1;
    display: flex;
    flex-direction: column;

    div.ui.input > input {
      flex: 0.6;
    }
  }
`;

export const TagsContainer = styled.div`
  z-index: 1;
  background-color: white;
  min-height: 100px;
  flex: 1;
`;

export const VariationArea = styled.div`
  position: relative;
  display: flex;
  min-height: 300px;
  justify-content: center;
  align-items: center;

  border-top: 1px solid ${borderColor};
`;

export const VariationListContainer = styled.div`
  display: flex;
  max-width: 1080px;
  flex-wrap: wrap;
  margin-top: 35px;
`;

export const AddVariationButton = styled(Button).attrs({
  primary: true,
  size: 'mini',
})`
  position: absolute;
  top: -2rem; // Minus? Yes because an attached label applies an !important margin-top onto the button
  right: 5px;
`;

export const LanguageIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem;
`;
